import React, { useState, Fragment, useEffect, useCallback } from 'react';
import { useHistory, Link } from "react-router-dom";
import firebase from 'firebase';
import { TextField, Button } from '@material-ui/core';
import firebaseError from 'utils/firebaseErrors';

import styles from './login.module.css';

const db = firebase.firestore();

const checkUserExist = async (email) => { 
  try {
    const mediaUsers = await db.collection("mediaUsers").where('email', '==', email).limit(1).get();

    return mediaUsers.docs.length !== 0 && mediaUsers.docs[0].exists
      ? {
          id: mediaUsers.docs[0].id,
          data: mediaUsers.docs[0].data(),
        }
      : {};
  } catch (e) {
    return {
      error: true,
      message: e.message,
    };
  }
}

const checkGarageExist = async (email) => {
  try {
    const garage = await db.collection("garages").where('email', '==', email).limit(1).get();

    return garage.docs.length !== 0 && garage.docs[0].exists
      ? {
          id: garage.docs[0].id,
          data: garage.docs[0].data(),
        }
      : {};
  } catch (e) {
    return {
      error: true,
      message: e.message,
    };
  }
}

export default () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [firstMount, setFirstMount] = useState(true);
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [passwordPage, setPasswordPage] = useState(false);

  const history = useHistory();

  const validateEmail = useCallback(async () => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === '') {
      setEmailError("L'adresse mail est obligatoire");
      return;
    } else if (!email.match(regex)) {
      setEmailError("L'adresse mail est invalide");
      return;
    }
    setEmailError('');
  }, [email]);

  useEffect(() => {
    if (!firstMount) {
      validateEmail();
      return;
    }
    setFirstMount(false);
  }, [email, firstMount, validateEmail])

  const auth = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const user = await checkUserExist(email);
      const garage = await checkGarageExist(email);

      if (!user.id || !garage.id) {
        setError('Adresse e-mail ou mot de passe invalide');
        setLoading(false);
        return;
      }

      await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);

      history.push('/');
      setLoading(false);
    } catch (error) {
      setError(firebaseError(error));
    }
    setLoading(false);
  }

  const handleOnChange = (e) => setEmail(e.target.value);

  const onSubmitEmail = (e) => {
    e.preventDefault();
    validateEmail();
    if (!emailError && email !== '') 
      setPasswordPage(true);
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  }

  return (
    <Fragment>
      <div className={styles.login}>
        <img className={styles.logo} src="checkAutologo@2x.svg" alt="" />
        <form noValidate>
          {
            !passwordPage ? (
              <Fragment>
                <TextField
                  type="email"
                  name="email"
                  label="Email"
                  fullWidth
                  margin="normal"
                  value={email}
                  onChange={handleOnChange}
                  error={!!emailError}
                  variant="outlined"
                />
                {emailError && <p className={styles.error}>{emailError}</p>}
                <Button
                  type="submit"
                  className={styles.loginBtn}
                  color="primary"
                  variant="contained"
                  id="send-password"
                  disabled={!!emailError || email === ''}
                  onClick={onSubmitEmail}
                >
                  Suivant
              </Button>
              </Fragment>
            )
              : (
                <Fragment>
                  <TextField
                    type="password"
                    name="password"
                    label="Mot de passe"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={password}
                    onChange={handlePasswordChange}
                    error={!!error}
                  />
                  {error && <p className={styles.error}>{error}</p>}
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    className={styles.loginBtn}
                    disabled={loading || !password}
                    onClick={auth}
                  >
                    {loading ? 'Chargement...' : 'Connexion'}
                  </Button>
                </Fragment>
              )}
        </form>
      </div>
      <Link className={styles.notice} to='/notice'>politique de confidentialité</Link>
      <div className={styles.bottomBar} style={{background: (emailError || error)? '#DA3F3F' : '#091F41'}}/>
    </Fragment>
  )
}